
import { defineComponent } from 'vue';
import { ProjectItem } from "@/models/ProjectItem";
import router from "@/router";
import ProjectItemStore from "@/stores/ProjectItemStore";

export default defineComponent({
  name: 'ProjectItemComponent',
  props: {
    item: ProjectItem,
    classOverride: String
  },
  methods: {
    openDetail(projectItem: ProjectItem){
      ProjectItemStore.activeProjectItem = projectItem;
    }
  }
});
